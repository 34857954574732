import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contacto' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Envianos un mensaje"
          subheader='Si querés charlar con nosotros, ténes dudas sobre algún tema, 
          si necesitas ayuda sobre temas financieros o asesoría no dudes en contactarnos 
          y te aconsejaremos de la mejor manera.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
