import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Estamos para ayudarte' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      Nos tomamos muy en serio nuestro compromiso con nuestros usuarios. 
      Si necesita nuestra ayuda sobre sus finanzas, 
      tiene preguntas sobre el tema o tiene alguna dificultad, no dude en contactarnos.
      </Text>
    </Card>
  </Section>
)

export default Commitment
